import React from "react";
import { useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import Layout from "@components/Layout";
import Header from "@components/Header";
import SEO from "@components/SEO";
import PageHeading from "@components/PageHeading";
import Card from "@components/Card";
import Link from "@components/Link";

const CARD_IMAGE_QUALITY = 75;

const ProjectsPage = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title={t("projects.title")}
        description={t("projects.description")}
        pathname={location.pathname}
      />
      <Header />
      <main>
        <div className="main-content-wrapper">
          <PageHeading>{t("projects.heading")}</PageHeading>
          <div className="projects">
            <Link
              href="https://wolvido.com"
              target="_blank"
              rel="noopener"
              outside
            >
              <Card
                image={
                  <StaticImage
                    src="../images/wolvido.png"
                    alt=""
                    quality={CARD_IMAGE_QUALITY}
                    placeholder="blurred"
                  />
                }
                heading={<h2>Wolvido</h2>}
                summary={<p>{t("projects.wolvido.summary")}</p>}
              />
            </Link>
            <Link
              href="https://youtubevideofinder.site"
              target="_blank"
              rel="noopener"
              outside
            >
              <Card
                image={
                  <StaticImage
                    src="../images/youtube-video-finder.png"
                    alt=""
                    quality={CARD_IMAGE_QUALITY}
                    placeholder="blurred"
                  />
                }
                heading={<h2>YouTube Video Finder</h2>}
                summary={<p>{t("projects.youtubeVideoFinder.summary")}</p>}
              />
            </Link>
            <Link
              href="https://sowebp.com"
              target="_blank"
              rel="noopener"
              outside
            >
              <Card
                image={
                  <StaticImage
                    src="../images/so-webp.png"
                    alt=""
                    quality={CARD_IMAGE_QUALITY}
                    placeholder="blurred"
                  />
                }
                heading={<h2>So WebP</h2>}
                summary={<p>{t("projects.soWebp.summary")}</p>}
              />
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
};

ProjectsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default ProjectsPage;
